<template>
    <div slot="no-body" class="tabs-container px-6 pt-6">

      <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
        <vs-tab label="Cafetería" icon-pack="feather" icon="icon-circle">
          <div class="tab-text">
            <payments-coffeshop @showPopup="showPopup"/>
          </div>
        </vs-tab>
        <vs-tab label="Escuela" icon-pack="feather" icon="icon-home">
          <div class="tab-text">
            <payments-school @showPopup="showPopup"/>
          </div>
        </vs-tab>
        <vs-tab label="Tienda" icon-pack="feather" icon="icon-shopping-cart">
          <div class="tab-text">
            <payments-store @showPopup="showPopup"/>
          </div>
        </vs-tab>
        <!-- <vs-tab label="Pago Directo" icon-pack="feather" icon="icon-chevrons-right">
          <div class="tab-text">
            <payments-direct-payment @showPopup="showPopup"/>
          </div>
        </vs-tab> -->
        <vs-tab label="Superadmin" icon-pack="feather" icon="icon-globe">
          <div class="tab-text">
            <payments-superadmin @showPopup="showPopup"/>
          </div>
        </vs-tab>
      </vs-tabs>

      <vs-popup fullscreen title="Recibo" :active.sync="popupActive">
        <iframe v-if="isPdf" :src="receiptUrl+'#toolbar=0'" frameborder="0" style="width:100%; height:100%;"></iframe>
        <vs-row v-else vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
            <img :src="receiptUrl" />
        </vs-row>
      </vs-popup>
    </div>
</template>

<script>
import PaymentsCoffeshop from "./PaymentsCoffeshop.vue";
import PaymentsSchool from "./PaymentsSchool.vue";
import PaymentsStore from "./PaymentsStore.vue";
import PaymentsDirectPayment from "./PaymentsDirectPayment.vue"
import PaymentsSuperadmin from "./PaymentsSuperadmin.vue";

export default {
  data() {
    return {
      activeTab: 0,

      popupActive: false,
      isPdf: false,
      receiptUrl: '',
    };
  },
  components: {
    PaymentsCoffeshop,
    PaymentsSchool,
    PaymentsStore,
    PaymentsDirectPayment,
    PaymentsSuperadmin
  },
  methods: {
    showPopup(isPdf, receipt) {
      this.isPdf = isPdf
      this.receiptUrl = receipt
      this.popupActive = true
    },
  },
};

</script>
